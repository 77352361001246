export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const ADDUSER_SUCCESS = 'ADDUSER_SUCCESS';
export const ADDUSER_FAIL = 'ADDUSER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGOUT = 'LOGOUT';
export const DELETEUSER_SUCCESS = 'DELETEUSER_SUCCESS';
export const DELETEUSER_FAIL = 'DELETEUSER_FAIL';
export const CLEAR_USERS = 'CLEAR_USERS';
export const GET_USERS = 'GET_USERS';
export const USERS_ERROR = 'USERS_ERROR';
export const GET_TRIPS = 'GET_TRIPS';
export const CLEAR_TRIPS = 'CLEAR_TRIPS';
export const TRIPS_ERROR = 'TRIPS_ERROR';
export const DELETETRIP_SUCCESS = 'DELETETRIP_SUCCESS';
export const DELETETRIP_FAIL = 'DELETETRIP_FAIL';
export const ADDTRIP_SUCCESS = 'ADDTRIP_SUCCESS';
export const ADDTRIP_FAIL = 'ADDTRIP_FAIL';
export const ADDIMAGE_BEGIN = 'ADDIMAGE_BEGIN';
export const ADDIMAGE_SUCCESS = 'ADDIMAGE_SUCCESS';
export const ADDIMAGE_FAIL = 'ADDIMAGE_FAIL';
export const GET_TRIP = 'GET_TRIP';
export const TRIP_ERROR = 'TRIP_ERROR';
export const CLEAR_TRIP = 'CLEAR_TRIP';
export const DELETEIMAGE_SUCCESS = 'DELETEIMAGE_SUCCESS';
export const DELETEIMAGE_FAIL = 'DELETEIMAGE_FAIL';
export const UPDATETRIP_SUCCESS = 'UPDATETRIP_SUCCESS';
export const UPDATETRIP_FAIL = 'UPDATETRIP_FAIL';
export const FORGOT_PASS_SUCCESS = 'FORGOT_PASS_SUCCESS';
export const FORGOT_PASS_FAIL = 'FORGOT_PASS_FAIL';
export const RESET_PASS_SUCCESS = 'RESET_PASS_SUCCESS';
export const RESET_PASS_FAIL = 'RESET_PASS_FAIL';

export const BOOK_CLEAR = 'BOOK_CLEAR';
export const BOOK_GETALL = 'BOOK_GETALL';
export const BOOK_GET = 'BOOK_GET';
export const BOOK_ERROR = 'BOOK_ERROR';
export const BOOK_GETLIST = 'BOOK_GETLIST';
export const BOOK_UPDATE_SUCCESS = 'BOOK_UPDATE_SUCCESS';
export const BOOK_UPDATE_FAIL = 'BOOK_UPDATE_FAIL';

export const CUSTOMER_ADD_SUCCESS = 'CUSTOMER_ADD_SUCCESS'
export const CUSTOMER_ADD_FAIL = 'CUSTOMER_ADD_FAIL'
export const CUSTOMER_LOADED = 'CUSTOMER_LOADED'
export const CUSTOMER_LOADED_FAIL = 'CUSTOMER_LOADED_FAIL'
export const CUSTOMER_UPDATE_SUCCESS = 'CUSTOMER_UPDATE_SUCCESS'
export const CUSTOMER_UPDATE_FAIL = 'CUSTOMER_UPDATE_FAIL'

